import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';

import { CustomStepProps, ZealTour } from '@zeal/web-ui';

import { useTour } from './useTour';
import { stepsMapping } from './components/stepsMapping';

const Tour = ({
	beforeCloseTour,
	t,
	elementId,
	stepName,
}: {
	beforeCloseTour?: () => void;
	t: any;
	elementId?: string;
	stepName?: string;
}) => {
	const { isElementPresent } = useTour({
		elementId: elementId || '',
		tourName: stepName || '',
	});

	const tourRef = useRef<{ gotoStep: (step: number) => void }>(null);

	const tourSteps: Array<CustomStepProps> = useMemo(() => {
		const stepsFunction = stepName && stepsMapping[stepName];
		return stepsFunction ? stepsFunction(t) : [];
	}, [stepName, t]);

	return (
		<ZealTour
			data-testid="tour"
			isOpen={isElementPresent}
			steps={tourSteps}
			type="withBar"
			showProgress={true}
			showBackBtn={true}
			disableInteractions={false}
		/>
	);
};

export default Tour;
