import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
i18next.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  debug: false,
  resources: {
    ar: {
      common: {
        someExampleKey: "\u0645\u062B\u0627\u0644 \u0639\u0644\u0649 \u0645\u0641\u062A\u0627\u062D"
      }
    },
    en: {
      common: {
        someExampleKey: "Some example key"
      }
    },
    es: {
      common: {
        someExampleKey: "Algun ejemplo clave"
      }
    }
  },
  partialBundledLanguages: true,
  load: "languageOnly",
  ns: [
    "common",
    "visits",
    "teams",
    "translation",
    "analytics",
    "segments",
    "orders",
    "activityLog"
  ],
  interpolation: {
    escapeValue: false
    // not needed for react as it escapes by default
  },
  defaultNS: "common",
  react: {
    wait: true,
    useSuspense: false
  }
});
export default i18next;
