import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { InlineLoading, Flex, Modal } from '@zeal/web-ui';

import Login from '../Login/Login';
import AppContainer from '../AppContainer';
import ErrorBoundary from '../ErrorBoundary';
import { ErrorFallback, NotFound } from '../Components';
import useSidebarSettings from '../data/useSidebarSettings';
import { useLoggedIn } from '../ACL/useLogin';
import { useGiftFriendRequestStatus } from '@data/GiftFriend';
import { useModalStore } from '../store/modalStore';
import { useFetchTranslation } from '../hooks';
import { useOrderNotification } from './useOrderNotification';
import { appPathEntries } from './appPathEntries';
import { renderRoute } from './renderRoute';
import { useBusinessProperties } from '@app/BusinessAccount/useBusinessInfo';
import { filterPaths } from '@app/Components/FilteredRoute';
import { ROUTES } from './routes';
import { NotificationAudioComponent } from './NotificationAudioComponent';
import ForgotPassword from '@app/ForgotPassword/forgotPassword';

const routes = appPathEntries;

const merchantRedirection = {
	freemium: '/freemium',
	premium: '/premium',
	cardlink: '/cardlink',
	null: '/dashboard',
	default: '/dashboard',
};

type RedirectionEnum = keyof typeof merchantRedirection;

export default function AppRoutes() {
	const modalStore = useModalStore((state) => state);

	const { isTranslationsLoading } = useFetchTranslation('common');

	React.useState({
		routes,
	});
	const { isLoggedIn, isNIMerchant, merchantType } = useLoggedIn();

	const { sidebarSettings, isLoading: isLoadingSidebarSettings } =
		useSidebarSettings();

	const { data: businessProperties } = useBusinessProperties();

	const allowedRoutes = sidebarSettings || {};

	const { data: giftFriendStatusData } = useGiftFriendRequestStatus();
	const isGiftFriendEnabled = giftFriendStatusData?.data?.is_enabled;

	const NiExcludedRoutes = [ROUTES.FEEDBACK.path];

	const isAllowedRoutesEmpty = Object.keys(allowedRoutes).length === 0;

	const filteredInternalRoutes = routes
		.filter(({ external }) => !external)
		.filter(
			({ path }) =>
				(!NiExcludedRoutes.includes(path) && isNIMerchant) || !isNIMerchant
		)
		.filter(
			({ apiKey }) => allowedRoutes[apiKey]?.visible || isAllowedRoutesEmpty
		)
		.filter(({ path }) =>
			path === ROUTES.GIFT_FRIEND.path ? isGiftFriendEnabled : true
		)
		.filter(({ path }) => filterPaths(businessProperties)({ to: path }));

	const filteredExternalRoutes = routes.filter(({ external }) => external);

	const internalAppRoutes = filteredInternalRoutes.map(({ routes }) => (
		<>{routes?.map(renderRoute)}</>
	));

	const externalAppRoutes = filteredExternalRoutes.map(({ routes }) => (
		<>{routes?.map(renderRoute)}</>
	));

	const dashboardURL =
		merchantRedirection[(merchantType ?? 'null') as RedirectionEnum];
	const isDashboardURLAllowed =
		dashboardURL &&
		filteredInternalRoutes.some(({ path }) => path === dashboardURL);

	const loggedInRedirection =
		(isDashboardURLAllowed || isAllowedRoutesEmpty
			? dashboardURL
			: filteredInternalRoutes[0]?.path) ?? merchantRedirection.default;

	const redirectUrl = isLoggedIn ? loggedInRedirection : ROUTES.LOGIN.path;

	useOrderNotification();

	// Refactor this with ui/ux team
	if ((isLoggedIn && isLoadingSidebarSettings) || isTranslationsLoading) {
		return (
			<Flex justify="center" align="center" className="h-screen">
				<InlineLoading />
			</Flex>
		);
	}

	return (
		<ErrorBoundary fallback={<ErrorFallback />}>
			<Modal {...modalStore}>{modalStore.children}</Modal>
			<NotificationAudioComponent />
			<Routes>
				<Route
					path={ROUTES.ROOT.path}
					element={<Navigate to={redirectUrl} replace />}
				/>
				<Route path={ROUTES.LOGIN.path} element={<Login />} />
				<Route
					path={ROUTES.FORGOT_PASSWORD.path}
					element={<ForgotPassword />}
				/>

				{externalAppRoutes}
				<Route element={<AppContainer />}>
					{internalAppRoutes}
					<Route path="*" element={<NotFound />} />
				</Route>
			</Routes>
		</ErrorBoundary>
	);
}
