import { CustomStepProps } from '@zeal/web-ui';

export const settingsSteps: (
	t: (key: string) => string
) => CustomStepProps[] = (t) => [
	{
		selector: '#settings-profile',
		title: t('settings_profile_title'),
		description: t('settings_profile_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
	},
	{
		selector: '#settings-category',
		title: t('settings_category_title'),
		description: t('settings_category_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
	},
	{
		selector: '#settings-account',
		title: t('settings_account_title'),
		description: t('settings_account_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
	},
	{
		selector: '#settings-pos',
		title: t('settings_pos_title'),
		description: t('settings_pos_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
	},
	{
		selector: '#settings-feedback',
		title: t('settings_feedback_title'),
		description: t('settings_feedback_description'),
		arrowPosition: 'left',
		tourPlace: 'right',
	},
];
