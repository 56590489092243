import i18next from 'i18next';
import { ENDPOINTS } from '@app/Services/endpoints';

export async function apiFetchAnnouncementFeature() {
	const apiToken = process.env.REACT_APP_STRAPI_TOKEN;

	const apiURL = process.env.REACT_APP_STRAPI_URL ?? '';

	const currentLanguage = i18next.resolvedLanguage?.substring(0, 2) || 'en';

	const result = await fetch(
		ENDPOINTS.dashboard.newFeatureAnnouncement(apiURL, currentLanguage),
		{
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${apiToken}`,
			},
		}
	).then((res) => res.json());

	return result;
}
