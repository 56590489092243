import React, { createContext, useContext, useState } from 'react';
import { TApiError } from '../../../QueryClient/types';
import {
	TMagicLinkFormContext,
	TMagicLinkForm,
	MagicLinkStepEnum,
} from '../../types';

export const MagicLinkFormContext = createContext<TMagicLinkFormContext>({
	step: MagicLinkStepEnum.EmailRegistration,
	goToNextStep: () => {},
	setStep: () => {},
	errors: {},
	setErrors: () => {},
});

export const useMagicLinkFormContext = () => useContext(MagicLinkFormContext);

export const MagicLinkFormProvider = ({
	children,
}: React.PropsWithChildren) => {
	const [errors, setErrors] = useState<TApiError<TMagicLinkForm>>({});
	const [step, setStep] = useState<MagicLinkStepEnum>(
		MagicLinkStepEnum.EmailRegistration
	);

	const goToNextStep = () => {
		setStep(step + 1);
	};

	const value: TMagicLinkFormContext = {
		step,
		errors,
		setErrors,
		goToNextStep,
		setStep,
	};

	return (
		<MagicLinkFormContext.Provider value={value}>
			{children}
		</MagicLinkFormContext.Provider>
	);
};
