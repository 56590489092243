export interface SettingsMenuItem {
	title: string;
	api_key: string;
	menu: {
		to: string;
		children: string;
		api_key: string;
		id?: string;
	}[];
}

const SettingsMenu: Array<SettingsMenuItem> = [
	{
		title: 'Settings',
		api_key: 'settings:settings_app_settings',
		menu: [
			{
				to: 'profile',
				children: 'Profile',
				api_key: 'settings:settings_app_settings:settings_profile',
				id: 'settings-profile',
			},
			{
				to: 'tapToPay',
				children: 'Tap To Pay',
				api_key: 'settings:settings_app_settings:settings_tap_to_pay',
			},
			{
				to: 'categories',
				children: 'Category',
				api_key: 'settings:settings_app_settings:settings_categories',
				id: 'settings-category',
			},
			{
				to: 'socialMedia',
				children: 'Social Media',
				api_key: 'settings:settings_app_settings:settings_social_media',
			},
			{
				to: 'paymentMethod',
				children: 'payment_method',
				api_key: 'settings:settings_app_settings:settings_payment_method',
			},
		],
	},
	{
		title: 'dashboard',
		api_key: 'settings:settings_dashboard',
		menu: [
			{
				to: 'account',
				children: 'Account',
				api_key: 'settings:settings_dashboard:settings_account',
				id: 'settings-account',
			},
			{
				to: 'billing',
				children: 'Billing',
				api_key: 'settings:settings_dashboard:settings_billing',
			},
			{
				to: 'stripe',
				children: 'Connect Stripe',
				api_key: 'settings:settings_dashboard:settings_stripe',
			},

			//TO DO
			// {
			// 	to: 'email',
			// 	children: 'Email',
			// },
			// {
			// 	to: 'payments',
			// 	children: 'Payment Options',
			// },
			// {
			// 	to: 'security',
			// 	children: 'Security',
			// },
			// {
			// 	to: 'pos',
			// 	children: 'Integrate POS',
			// },
			// {
			// 	to: 'subscription',
			// 	children: 'Subscription',
			// },
			// {
			// 	to: 'appearance',
			// 	children: 'Appearance',
			// },
		],
	},
	{
		title: 'cardlink_settings',
		api_key: 'settings:cardlink_settings',
		menu: [
			{
				to: 'webhookSettings',
				children: 'webhook_settings',
				api_key: 'settings:cardlink_settings:webhook_settings',
			},
		],
	},

	{
		title: 'booking',
		api_key: 'settings:settings_booking',
		menu: [
			{
				to: 'booking',
				children: 'booking_settings',
				api_key: 'settings:settings_booking:settings_booking_settings',
			},
			{
				to: 'refund',
				children: 'refund_polices',
				api_key: 'settings:settings_booking:settings_refund',
			},
		],
	},
	{
		title: 'settings_pos_terminal',
		api_key: 'settings:settings_pos_terminal',
		menu: [
			{
				to: 'posLanguage',
				children: 'POS App Language',
				api_key: 'settings:settings_pos_terminal:settings_pos_language',
				id: 'settings-pos',
			},
			{
				to: 'feedback',
				children: 'feedback',
				api_key: 'settings:settings_pos_terminal:settings_feedback',
				id: 'settings-feedback',
			},
		],
	},
	{
		title: 'terms_and_conditions',
		api_key: 'settings:cardlink_settings',
		menu: [
			{
				to: 'termsAndConditions',
				children: 'zeal_cardlink_terms_and_conditions',
				api_key: 'settings:cardlink_settings:terms_conditions_settings',
			},
		],
	},
];
export default SettingsMenu;
