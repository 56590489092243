import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { Button, Flex, Image, Label } from '@zeal/web-ui';
import ErrorImage from '@modules/assets/error.svg';

const RELOAD_KEY = 'zeal-reloaded';

export default function ErrorFallback() {
	const { t } = useTranslation('common');
	const [shouldRender, setShouldRender] = useState(false);

	const handleReload = useCallback(() => {
		window.location.reload();
	}, []);

	useEffect(() => {
		const reloaded = localStorage.getItem(RELOAD_KEY);

		if (reloaded === 'false' || reloaded === null) {
			handleReload();
		} else {
			setShouldRender(true);
		}

		localStorage.setItem(RELOAD_KEY, 'true');
	}, []);

	if (!shouldRender) {
		return <></>;
	}

	return (
		<Flex
			isColumn
			gap="xl"
			justify="center"
			align="center"
			className="max-w-xl mx-auto text-center min-h-screen py-4"
		>
			<Flex isColumn gap="base" justify="center" align="center">
				<Label variant="black" weight="bold" size="5xl">
					{t('something_went_wrong')}
				</Label>
				<Label variant="black" size="lg">
					{t('sorry_msg')}
				</Label>
				<Button variant="primary" onClick={handleReload}>
					{t('reload_page')}
				</Button>
			</Flex>
			<Image src={ErrorImage} />
		</Flex>
	);
}
