import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from '@zeal/web-ui';

import { LanguageSelectorContainer } from './StyledComponents';
import { useFetchLocals } from '@app/hooks';

interface Props {
	isLoginPage?: boolean;
}

const LanguageSelector: React.FC<Props> = ({ isLoginPage }) => {
	const { i18n } = useTranslation();
	const { language, changeLanguage, resolvedLanguage } = i18n;

	const { data } = useFetchLocals();

	const languageOptions = useMemo(
		() =>
			(data || [])?.map((lan) => ({
				value: lan.code,
				label: lan.name,
				id: lan.id,
			})),
		[data]
	);

	const selectedLanguage =
		useMemo(
			() => languageOptions.find((lng) => lng.value === resolvedLanguage),
			[resolvedLanguage, languageOptions]
		) || languageOptions[0];

	const handleLanguageChange = useCallback(
		(item) => {
			changeLanguage(item.value);
		},
		[changeLanguage, i18n.resolvedLanguage]
	);

	const selectedColor = isLoginPage ? 'textOnly' : 'default';

	return (
		<LanguageSelectorContainer>
			<Select
				placeholder="Select language"
				options={languageOptions}
				selected={selectedLanguage}
				onChange={handleLanguageChange}
				disabled={false}
				color={selectedColor}
			/>
		</LanguageSelectorContainer>
	);
};

export default LanguageSelector;
