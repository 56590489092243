import { generatedKeys } from '@app/data';
import { fetchLocales, fetchTranslations } from '@app/Services/App';
import i18next from 'i18next';
import { camelCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useInfiniteQuery,
	useQuery,
	UseQueryOptions,
} from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

// this mapper maps the translation moduleName to the translation collection name (in camel case) on the CMS
const translationModuleMap: Record<string, string> = {
	cardlinkDashboard: 'dashboard',
	customersManagement: 'customers',
	visitsManagement: 'visits',
	menuManagement: 'menu',
	freemium: 'freemiumDashboard',
	premium: 'freemiumDashboard',
	techniciansSlots: 'schedule',
	cardlinkSetup: 'cardlink',
	segments: 'segments',
	cardlink: 'freemiumDashboard',
	loyalty: 'freemiumLoyalty',
	administration: 'freemiumTeams',
	calendar: 'ordersCalendar',
	freeTier: 'magicLink',
	branchesAvailability: 'branches',
	home: 'freeHome',
	'points-and-rewards': 'pointsAndReward',
};

const getTranslationKey = (moduleName: string) => {
	const moduleMainRoute = moduleName?.split('/')?.[1];

	const moduleNameSlug = camelCase(moduleMainRoute || moduleName);

	const secondarySlug = moduleName?.split('/')?.[2];

	const mappedKey =
		translationModuleMap[secondarySlug] ||
		translationModuleMap[moduleNameSlug] ||
		moduleNameSlug;

	if (
		secondarySlug === 'customers' &&
		moduleNameSlug === 'customersManagement' &&
		moduleName?.split('/')?.[3]?.length > 2
	) {
		return 'profile';
	}

	return mappedKey;
};

export const useFetchTranslation = (moduleName: string) => {
	const { i18n } = useTranslation();
	const [isLoading, setIsLoading] = useState(true);

	// TODO: Rename the translation files to match the module names
	const lang = (i18n.resolvedLanguage || i18n.language)?.substring(0, 2);

	const mappedKey = getTranslationKey(moduleName);

	const q = useInfiniteQuery({
		queryKey: ['z-translations', lang, mappedKey],
		queryFn: ({ pageParam = 1 }: { pageParam: number }) =>
			fetchTranslations(lang, mappedKey, pageParam),
		getNextPageParam: (lastPage) => {
			const currentPage = lastPage?.meta?.pagination?.page || 1;
			const totalPages = lastPage?.meta?.pagination?.pageCount || 1;

			return currentPage < totalPages ? currentPage + 1 : undefined;
		},
		select: (data) => {
			const allData = data?.pages?.reduce((acc, page) => {
				acc = {
					...acc,
					...page.translationsObj,
				};

				return acc;
			}, {});

			return allData;
		},
		enabled: !!lang && !!mappedKey,
		staleTime: Infinity,
		refetchOnWindowFocus: false,
		refetchInterval: false,
		refetchIntervalInBackground: false,
		retry: false,
	});

	useEffect(() => {
		if (q.hasNextPage) {
			q.fetchNextPage();
		} else {
			console.log('Adding translations to i18next', lang, mappedKey, q.data);
			i18next.addResourceBundle(lang, mappedKey, q.data || {}, false, true);

			// trigger a re-render
			i18next.changeLanguage(lang);

			setIsLoading(false);
		}
	}, [q.hasNextPage, q.data]);

	useEffect(() => {
		if (q.error) {
			Sentry.captureException(q.error);
			setIsLoading(false);

			throw new Error(`Failed to fetch translations for ${moduleName}`);
		}
	}, [q.error]);

	return {
		...q,
		isTranslationsLoading:
			isLoading || q.isFetching || q.isFetchingNextPage || q.hasNextPage,
	};
};

export const useFetchLocals = (
	opt?: UseQueryOptions<Awaited<ReturnType<typeof fetchLocales>>>
) => {
	const q = useQuery<Awaited<ReturnType<typeof fetchLocales>>>({
		queryFn: fetchLocales,
		staleTime: Infinity,
		queryKey: generatedKeys.translations.locales?.({}),
		...opt,
	});

	if (q.error) {
		Sentry.captureException(q.error);

		throw new Error('Failed to fetch locales');
	}

	return { ...q, isLocalesLoading: q.isLoading };
};
