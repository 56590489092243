import React from 'react';

import { Container } from '@app/Login/Login';
import { Flex } from '@zeal/web-ui';

import NavMenu from './components/NavMenu';
import { MagicLinkFormProvider } from './components/form/FormContext';
import { useFetchTranslation } from '@app/hooks';

export default function MagicLinkLayout({
	children,
}: {
	children: React.ReactNode;
}) {
	const { isTranslationsLoading } = useFetchTranslation('magicLink');

	return (
		<Container>
			<NavMenu />
			<Flex className="pt-16">
				<MagicLinkFormProvider>{children}</MagicLinkFormProvider>
			</Flex>
		</Container>
	);
}
