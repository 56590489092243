import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Card, Grid } from '@zeal/web-ui';

import { usePageConfig } from '@app/hooks/usePageConfig';
import { useTourStore } from '@app/store/tourStore';
import SettingsMenuCard from './data/MenuMap';
import Tour from '@app/Tour/Tour';

export default function SettingsFrame(props: React.PropsWithChildren<{}>) {
	const { t } = useTranslation('settings');

	usePageConfig({ title: t('settings') });

	const { setActiveTour, activeTour } = useTourStore();

	const location = useLocation();

	React.useEffect(() => {
		if (location.pathname === '/settings/profile') {
			setActiveTour('settings');
		}
	}, [activeTour, location, setActiveTour]);

	return (
		<Card m="md" p="none">
			<Grid
				cols={6}
				gap="md"
				className="[grid-template-columns: minmax(250px, 1fr) repeat(5, 1fr)]"
			>
				<div className="h-full min-h-screen col-span-1 p-4 border-r-2 border-gray-100">
					{SettingsMenuCard}
				</div>
				<div className="h-full col-span-5 px-6 py-4">{props.children}</div>
				<Tour t={t} elementId="settings-profile" stepName="settings" />
			</Grid>
		</Card>
	);
}
