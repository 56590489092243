import ms from "ms";
import { ApiRequest, FeatureToggles } from "@zeal/zeal-lib";
import { Cookies, useCookies } from "react-cookie";
import { useQueryString } from "../useQueryString";
import {
  useAuthStore,
  useLoginVault,
  usePermissions
} from "../store/authStore";
import { useAppStore } from "../store/appStore";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { queryClient, useMutation, useQuery } from "@app/QueryClient";
import { startTransition } from "react";
import openToaster from "@app/Toastr/openToastr";
import { generatedKeys } from "@app/data";
import { ROUTES } from "@app/AppRoutes/routes";
import { parseError } from "@app/QueryClient/QueryClient";
const { login } = generatedKeys;
export const TOKEN_TTL = ms("1d");
export const TOKEN_COOKIE_NAME = "zeal-portal-token";
export function onLoginSuccess({ data, payload, keepSignIn }) {
  const userUUID = data.business_admin.uuid;
  const accessLevel = data?.business_admin?.roles?.[0]?.name;
  const name = data?.business_admin?.name;
  const isUkMerchant = data?.is_uk || false;
  const permissions = (data?.business_admin?.roles || []).reduce(
    (acc, role) => [...acc, ...role.permissions || []],
    []
  );
  const isSuperAdmin = data?.business_admin?.is_super_admin || false;
  setTimeout(() => {
    FeatureToggles.init(
      {
        sync: {
          impressionsMode: "DEBUG"
        },
        core: {
          key: payload.email,
          trafficType: "users",
          authorizationKey: process.env.REACT_APP_SPLIT_API_KEY
        }
      },
      {
        isSuperAdmin,
        portal: "zeal-portal"
      }
    );
  }, 0);
  return {
    userUUID,
    token: data?.token,
    permissions,
    keepSignIn,
    isSuperAdmin,
    payload: data?.business_admin,
    name,
    accessLevel,
    isUkMerchant,
    merchantType: data?.type
  };
}
export function onLoginError({ error, keepSignIn }) {
  const isDev = process.env.ALLOW_DEV_TOOLS === "yes";
  const isFakeLogin = isDev && localStorage.getItem("dev/log-me-in") === "please";
  if (isFakeLogin) {
    return {
      keepSignIn,
      token: "fake-login"
    };
  }
  throw error;
}
async function apiLogin({ keepSignIn, ...payload }) {
  return new ApiRequest("v3/auth/login").addHeader("Accept", "application/json").addHeader("Content-Type", "application/json").POST(JSON.stringify(payload), { noAuth: true }).then(({ data }) => onLoginSuccess({ data, payload, keepSignIn })).catch((error) => onLoginError({ error, keepSignIn }));
}
async function apiLogout(body) {
  return new ApiRequest("v4/auth/logout").addHeader("Accept", "application/json").addHeader("Content-Type", "application/json").POST(JSON.stringify(body));
}
async function apiLoginData() {
  return new ApiRequest("v4/business-admin/login-data").addHeader("Accept", "application/json").GET();
}
export function useLoggedIn() {
  const { isSuperAdmin, isNIMerchant, name, accessLevel, merchantType } = useLoginVault();
  const [cookies] = useCookies([TOKEN_COOKIE_NAME]);
  const { permissions, cuuid } = usePermissions();
  const { updatePermissions } = useAuthStore((state) => state);
  const cookieToken = cookies[TOKEN_COOKIE_NAME];
  const updateCachedUserPermissions = (permissions2) => {
    updatePermissions(permissions2);
  };
  return {
    token: cookieToken,
    isNIMerchant,
    userUUID: cuuid,
    isLoggedIn: !!cookieToken,
    authorization: `Bearer ${cookieToken}`,
    isSuperAdmin: isSuperAdmin || false,
    permissions: permissions || [],
    updateCachedUserPermissions,
    name,
    accessLevel,
    merchantType
  };
}
export function onLoginMutationSuccess({ data, authenticate, opt }) {
  const loginVal = {
    id: (/* @__PURE__ */ new Date()).getTime(),
    keepSignIn: !!data?.keepSignIn,
    isSuperAdmin: data?.isSuperAdmin,
    uuid: data?.userUUID,
    businessName: data?.payload?.business?.name,
    email: data?.payload?.email,
    type: data?.payload?.business?.type,
    isNIMerchant: data?.payload?.business?.type === "ni",
    name: data?.name,
    accessLevel: data?.accessLevel,
    isUkMerchant: data?.isUkMerchant,
    merchantType: data?.merchantType
  };
  const cookies = new Cookies(null, { path: "/" });
  cookies.set(TOKEN_COOKIE_NAME, data.token, {
    maxAge: TOKEN_TTL / 1e3,
    secure: process.env.ZEAL_ENV === "production"
  });
  const permissions = {
    permissions: data?.permissions,
    cuuid: data.userUUID
  };
  authenticate(loginVal, permissions);
  opt?.onSuccess?.(data);
}
export function useLogin(opt) {
  const authenticate = useAuthStore((state) => state.authenticate);
  const mutation = useMutation(apiLogin, {
    disableToaster: true,
    onSuccess: (data) => {
      onLoginMutationSuccess({
        data,
        authenticate,
        opt
      });
    },
    onError: async (error) => {
      const parsedError = await parseError(error);
      const toastId = openToaster({
        variant: "error",
        message: parsedError?.message || "Wrong email or password"
      });
      opt?.onError?.({ ...error, toastId });
    }
  });
  const { mutate } = mutation;
  return {
    ...mutation,
    doLogin: mutate
  };
}
export function useLogout(opt) {
  const logout = useAuthStore((state) => state.logout);
  const clearData = useAppStore((state) => state.clearData);
  const { t } = useTranslation();
  const [, updateQuery, , deleteQuery] = useQueryString();
  const navigate = useNavigate();
  const mutation = useMutation(apiLogout, {
    disableToaster: true,
    onMutate: () => {
      window.indexedDB.databases().then((databaseList) => {
        databaseList.forEach((db) => {
          window.indexedDB.deleteDatabase(db.name);
        });
      });
    },
    onSuccess: (data) => {
      startTransition(() => {
        toast.dismiss();
        navigate(ROUTES.LOGIN.path);
        logout();
        clearData();
        queryClient.removeQueries();
      });
      opt?.onSuccess?.(data);
      deleteQuery();
      updateQuery();
    },
    onError: (err) => {
      toast.error(err.error.message ?? t("something_went_wrong"));
    }
  });
  const { mutate, isLoading } = mutation;
  return {
    ...mutation,
    isLoading,
    doLogout: mutate
  };
}
export function getLoggedInSession() {
  const {
    uuid,
    businessName,
    email,
    name,
    accessLevel,
    isSuperAdmin,
    isUkMerchant,
    merchantType
  } = useAuthStore.getState().loginVault || {};
  const cookies = new Cookies(null, { path: "/" });
  const cookieToken = cookies.get(TOKEN_COOKIE_NAME);
  return {
    token: cookieToken,
    isLoggedIn: !!cookieToken,
    authorization: `Bearer ${cookieToken}`,
    uuid,
    businessName,
    email,
    name,
    accessLevel,
    isSuperAdmin,
    isUkMerchant,
    merchantType
  };
}
export function useLoginData() {
  const q = useQuery({
    queryFn: apiLoginData,
    staleTime: 5 * 60 * 1e3,
    // 5 minutes
    queryKey: login?.data?.({})
  });
  return {
    ...q,
    data: q?.data?.data
  };
}
