import React, { useCallback, useMemo, useState } from 'react';
import { DropdownMenu, Flex, Icon, Label } from '@zeal/web-ui';
import UK from 'country-flag-icons/react/3x2/GB';
import EG from 'country-flag-icons/react/3x2/EG';

import LanguageButton from './LanguageButton';
import { useTranslation } from 'react-i18next';
import { StyledFreemiumLanguageTrigger } from './StyledComponents';
import { useFetchLocals } from '@app/hooks';

const countryFlagsMap = {
	en: (
		<UK
			title="United Kingdom"
			className="w-6"
			style={{
				height: '14px',
			}}
		/>
	),
	ar: (
		<EG
			title="Egypt"
			className="w-6"
			style={{
				height: '14px',
			}}
		/>
	),
};

export default function FreemiumLanguageSelector() {
	const [isClosed, setIsClosed] = useState(true);
	const handleOpenChange = useCallback(
		(isClosed: boolean) => setIsClosed(!isClosed),
		[]
	);

	const { i18n } = useTranslation();

	const [chosenLanguage, setChosenLanguage] = useState(i18n.language);

	const { data } = useFetchLocals();

	const languageOptions = useMemo(() => data || [], [data]);

	const generateLanguageButtons = languageOptions.map((i) => (
		<LanguageButton
			key={i.id}
			value={i.code}
			label={i.name}
			onClick={() => {
				setIsClosed(true);
				setChosenLanguage(i.code);
			}}
			flag={countryFlagsMap[i.code as keyof typeof countryFlagsMap]}
		/>
	));

	return (
		<DropdownMenu onOpenChange={handleOpenChange} open={!isClosed}>
			<DropdownMenu.Trigger className="group">
				<StyledFreemiumLanguageTrigger
					align="center"
					justify="center"
					isClosed={isClosed}
				>
					{countryFlagsMap[chosenLanguage as keyof typeof countryFlagsMap]}
					<Label.Mid300>{chosenLanguage.toUpperCase()}</Label.Mid300>
					<Icon
						iconName="chevronDown"
						variant="regular"
						colorVariant="darkGrey"
					/>
				</StyledFreemiumLanguageTrigger>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content style={{ width: '180px', padding: '8px' }}>
				<Flex isColumn gap="sm">
					{generateLanguageButtons}
				</Flex>
			</DropdownMenu.Content>
		</DropdownMenu>
	);
}
