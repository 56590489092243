export enum EMagicLinkType {
	VERIFICATION = 2,
	REGISTRATION = 3,
}

export type TApiGetEmaiResponse = {
	email: string;
	type: EMagicLinkType;
};

export type TMagicLinkForm = {
	type: EMagicLinkType;
	token: string;
	email: string;
	code: string;
	password: string;
	password_confirmation: string;
	accepted_terms: boolean;
};

export enum MagicLinkStepEnum {
	EmailRegistration = 1,
	CodeVerification,
	CreatePassword,
	VerificationSent,
	LoginToAccount,
	AccountVerified,
}

export type TMagicLinkFormContext = {
	step: MagicLinkStepEnum;
	goToNextStep: () => void;
	errors: Record<string, any>;
	setErrors: (value: Record<string, any>) => void;
	setStep: (value: MagicLinkStepEnum) => void;
};

export type TLoginForm = {
	email: string;
	password: string;
	keepSignIn: boolean;
};
