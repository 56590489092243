import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { getAccountInfo } from '../BusinessAccount/useBusinessInfo';
import { useAppState } from '../store/appStore';

export const useQueryKey = ({
	queryKey,
}: {
	queryKey: any[] | readonly any[];
}) => {
	const {
		i18n: { resolvedLanguage, language },
	} = useTranslation();
	const currentLanguage = (resolvedLanguage || language) ?? 'en';

	const { currentAppId, currentAppName } = useAppState();

	return [...queryKey, [currentAppName, currentAppId, currentLanguage]];
};

export const getQueryKey = (queryKey: readonly any[]) => {
	const { appId, appName } = getAccountInfo();
	const currentLanguage =
		(i18next.resolvedLanguage || i18next.language) ?? 'en';

	return [...queryKey, [appName, appId, currentLanguage]];
};
